
$(document).ready(function () {

  new Swiper(".swiper2", {
    slidesPerView: 4,
    spaceBetween: 30,
    speed: 1000,
    centeredSlides: true,
    loop: false,
    centerSlide: "true",
    grabCursor: "true",
    initialSlide: 2,
    watchSlidesProgress: true,
    autoplay: {
      delay: 2500,
      disableOnInteraction: true,
    },
    scrollbar: {
      el: '.swiper-scrollbar',
      draggable: true,
    },
    // navigation: {
    //   nextEl: ".swiper-button-next",
    //   prevEl: ".swiper-button-prev",
    // },
    breakpoints: {
      0: {
        slidesPerView: 1,
      },
      769: {
        slidesPerView: 2,
      },
      1025: {
        slidesPerView: 4,
      },
    },
  });

  $(".our-wow-btn").on("click", function () {

    $(".form-container").css('display', 'flex');
    $(".form-submitted").css('display', 'none');
    $("#form").css('background', '#F9F5EF');
    $("#form").css("display", "block");
    $("html").css("overflow-y", "hidden");
    //window.location.replace("form.html");

  });

});



